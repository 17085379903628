<template>
  <div class="Edit_meeting show_meeting">
  
      <div class="row page_header_all">
      <div class="col-lg-6 col-12 right_content">
        <div class="page_title">
          <div class="title_icon">
            <img src="/media/svg/plus.svg" />
          </div>
             <h3 class="title">بيانات الإجتماع</h3>
        </div>
      </div>
      <div class="col-lg-6 action_buttons mr_small">
        <button
          class="btn_Green btn_main m-0"
          @click="$router.push('/new_meeting')"
        >
    
           
             <span> حفظ وخروج </span>
        </button>
      </div>
    </div>

    <div class="form_container meeting-link">
      <div class="form_header heeder_edit_meeting row">
        <div class="meeting-input col-md-9">
          <div class="title">
            <span>عنوان الاجتماع</span>
          </div>
          <div class="form_body">
            <form class="row">
              <div class="form-group col-md-12 d-block">
                <input
                  v-model="meeting.title"
                  class="input_title"
                  type="text"
                  placeholder="عنوان الإجتماع"
                  id="group_name"
                />
              </div>
              <div class="form-group d-flex col-md-12 justify-content-between">
                <div>
                  <label>تاريخ الاجتماع</label>
                  <!--   {{ meeting.start_time | moment("Do MMMM  YYYY") }} -->
                  <b-form-datepicker
                    :no-flip="true"
                    v-model="meetingDate"
                    :min="new Date()"
                    locale="ar"
                  ></b-form-datepicker>
                </div>
                <div>
                  <label>بداية الاجتماع</label>

                  <b-form-timepicker
                    :no-close-button="true"
                    :hide-header="true"
                    :no-flip="true"
                    :hour12="false"
                    label-no-time-selected="لم يتم إختيار وقت"
                    minutes-step="5"
                    v-model="start_time_h"
                    locale="ar"
                  ></b-form-timepicker>
                </div>
                <div>
                  <label>نهاية الاجتماع</label>
                  <b-form-timepicker
                    :no-close-button="true"
                    :hide-header="true"
                    :no-flip="true"
                    :hour12="false"
                    label-no-time-selected="لم يتم إختيار وقت"
                    minutes-step="5"
                    v-model="end_time_h"
                    locale="ar"
                  ></b-form-timepicker>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- <div class="col-md-5 d-flex" style="padding: 8px">
       
               <a
          
            class="btn_brimary create_btn    "
          >
            <i style="color: white" class="fas fa-copy"></i>
            <span> نسخ رابط الاجتماع </span>
          </a>
          <a
          class="btn_second create_btn"
          >
            <i style="color: white" class="fas fa-edit"></i>
            <span> تعديل </span>
          </a>
         
       
        </div> -->
      </div>
      <div class="form_body mt-9 row">
        <div class="borderd_bottom w-100">
          <form class="col-md-10 col-12">
            <div class="form-group col-md-12 d-block">
              <label class="item_label">
                <span>ربط الاجتماع بمبادرة سابقة</span>
                <span
                  style="
                    font-size: 15px;
                    font-weight: normal;
                    margin-right: 10px;
                  "
                  >(اختياري)</span
                >
              </label>
              <div class="input-search position-relative">
                <v-select
                  v-model="meeting.parent_meeting"
                  :options="meetingsList"
                  placeholder="بحث المبادرات السابقة"
                  label="title"
                  @search="getMeetings"
                />
              </div>
            </div>
            <div class="form-group col-md-12 d-block">
              <label class="item_label"> طبيعة الاجتماع </label>

              <v-radio-group
                v-model="meeting.nature_of_meeting"
                mandatory
                class="row"
              >
                <div class="col-md-4 col-12">
                  <v-radio
                    label="إجتماع داخلي"
                    value="internal_meeting"
                  ></v-radio>
                </div>
                <div class="col-md-4 col-12">
                  <v-radio
                    label="إجتماع مع عميل"
                    value="client_meetning"
                  ></v-radio>
                </div>
              </v-radio-group>
            </div>
                     <div class="form-group col-md-12 d-block">
              <label class="item_label">نوع الاجتماع</label>
              <v-radio-group
                v-model="meeting.meeting_type"
                mandatory
                class="row"
              >
                <div class="col-md-4 col-12">
                  <v-radio
                          id="meeting_type"
                          label="خاص"
                          value="private"
                        ></v-radio>
                </div>
                <div class="col-md-6 col-12">
                  <v-radio
                          id="more"
                          label="عام (يمكن دخول أخرين خلال الاجتماع)"
                          value="public"
                        ></v-radio>
                </div>
                
              </v-radio-group>
            </div> 
          <!--  <div class="form-group col-md-12 d-block">
              <label class="item_label">درجه الأهمية</label>
              <v-radio-group
                v-model="meeting.priority_level"
                mandatory
                class="row"
              >
                <div class="col-md-4 col-12">
                  <v-radio
                    id="less"
                    label="أقل اهمية"
                    value="less_serious"
                  ></v-radio>
                </div>
                <div class="col-md-4 col-12">
                  <v-radio id="more" label="مهم" value="serious"></v-radio>
                </div>
                <div class="col-md-4 col-12">
                  <v-radio
                    id="extremly"
                    label="شديد الأهمية"
                    value="very_serious"
                  ></v-radio>
                </div>
              </v-radio-group>
            </div> -->
            <!-- <div class="form-group col-md-12 d-block">
              <label class="item_label">
                <span>ربط الاجتماع ب task</span>
                <span
                  style="
                    font-size: 15px;
                    font-weight: normal;
                    margin-right: 10px;
                  "
                  >(اختياري)</span
                >
              </label>
              <div class="input-search position-relative">
                <input
                  v-model="meeting.task_link"
                  class="form-control"
                  type="search"
                  name="meeting-link"
                  placeholder="اسم ال task"
                />
              </div>
            </div> -->
            <div class="form-group col-md-12 col-12">
              <div class="title">
                <span class="  d-block">منسق الاجتماع</span>
              </div>
              <div class="input-search position-relative customSelect">
                <multiselect
                  v-model="meeting.coordinator"
                  :taggable="false"
                  track-by="id"
                  placeholder="إسم الشخص"
                  label="name"
                  :options="allMembers"
                  :option-height="150"
                  :show-labels="false"
                  :multiple="false"
                >
                  <template slot="option" slot-scope="props">
                    <span class="parent">
                      <img :src="props.option.avatar" />
                      <span class=" ">
                        {{ props.option.name }}</span
                      >
                    </span>
                  </template>
                </multiselect>
                <button class="search-icon">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
        <!-- invited Users -->
        <div class="col-md-12 col-12">
          <div
            class="title mt-6"
            style="justify-content: space-between; display: flex"
          >
            <span>المدعوين</span>
            <button
              type="button"
              @click="EditMeetingMembers()"
              class="btn_second"
            >
              <img src="/media/svg/edit_w.svg" />
              تعديل
            </button>
          </div>

          <draggable
            class="row"
            :list="allMembersSelected"
            :group="{ name: 'people', pull: 'clone', put: false }"
          >
            <div
              class="col-12 col-sm-6 col-md-4 col-lg-4"
              v-for="(element, index) in allMembersSelected"
              :key="element.name"
            >
              <div class="user_data d-flex">
                <img :src="element.avatar" />
                <span class="user_name">{{ element.name }}</span>
                <div class="list_actions">
                  <div class="item-remove" @click="removeMember(index)">
                    <i class="fas fa-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </draggable>

          <draggable
            class="row"
            :list="meeting.groups"
            :group="{ name: 'people', pull: 'clone', put: false }"
          >
            <div
              class="col-12 col-sm-6 col-md-4 col-lg-4"
              v-for="(element, index) in meeting.groups"
              :key="element.name"
            >
              <div class="user_data d-flex">
                <img :src="element.image" />
                <span class="user_name">{{ element.name }}</span>
                <div class="list_actions">
                  <b-dropdown
                    v-if="element.users.length"
                    position="is-bottom-left"
                    class="dropdownList m-0"
                    aria-role="list"
                  >
                    <template #trigger>
                      <a class="navbar-item p-0" role="button">
                        <div class="item-eye">
                          <i class="fas fa-eye"></i>
                        </div>
                      </a>
                    </template>
                    <b-dropdown-item
                      paddingless
                      aria-role="listitem"
                      v-for="user in element.users"
                      :key="user.id"
                    >
                      <div class="user_data mb-2">
                        <img :src="user.avatar" />
                        <span class="user_name">{{ user.name }}</span>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown
                    v-else
                    position="is-bottom-left"
                    class="dropdownList m-0"
                    aria-role="list"
                  >
                    <template #trigger>
                      <a class="navbar-item p-0" role="button">
                        <div class="item-eye">
                          <i class="fas fa-eye"></i>
                        </div>
                      </a>
                    </template>
                    <b-dropdown-item paddingless aria-role="listitem">
                      <div class="user_data mb-2">
                        <span class="user_name">لا يوجد اعضاء</span>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                  <div class="item-remove" @click="removeGroup(index)">
                    <i class="fas fa-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </draggable>
        </div>
        <div class="col-md-12 col-12">
          <div
            class="title mt-6"
            style="justify-content: space-between; display: flex"
          >
            <span>المهام المطلوبة</span>
            <button
              type="button"
              @click="update_meeting_tasks()"
              class="btn_second"
            >
              <img src="/media/svg/edit_w.svg" />
              تعديل
            </button>
          </div>
          <div class="d-flex mb-5">
            <div class="col-md-3 to_drop_in">
              <draggable
                class="list-group p-1"
                :list="TaskList"
                :group="{ name: 'people', pull: false, put: true }"
              >
                <template v-if="TaskList.length">
                  <div
                    v-for="element in TaskList"
                    :key="element.name"
                    class="listItem"
                  >
                    <div class="user_data mb-2">
                      <img
                        :src="element.image ? element.image : element.avatar"
                      />
                      <span class="user_name">{{ element.name }}</span>
                    </div>
                  </div>
                </template>
                <div class="selectPlaceholder" v-else>إضافة شخص</div>
              </draggable>
            </div>
            <div class="input-search tasks_input position-relative w-100">
              <input
                v-model="taskText"
                class="form-control"
                type="search"
                name="meeting-link"
                id=""
                placeholder="المهمة المطلوب تنفيذها"
              />
              <!--  -->
              <button type="button" @click="btnAddTask" class="add_input_icon">
                <img src="/media/svg/plus.svg" />
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-12">
          <div class="tasks" v-if="MeetingTasks.length">
            <ul>
              <li v-for="(task, index) in MeetingTasks" :key="task.id">
                <div
                  v-if="Edit_Tasks && edited_task == index"
                  class="d-block bg_gray p-4"
                >
                  <div class="d-flex position_relative">
                    <b-dropdown
                      position="is-bottom-left"
                      class="dropdownList"
                      aria-role="list"
                    >
                      <template #trigger>
                        <a
                          class="navbar-item p-0"
                          role="button"
                          v-if="task.users && task.users.length"
                        >
                          <div v-if="task.users.length" class="user_data m-0">
                            <img :src="task.users[0].avatar" />
                            <span class="user_name ml-2">{{
                              task.users[0].name
                            }}</span>
                            <b-icon icon="menu-down mr-auto"></b-icon>
                          </div>
                          <div v-else class="user_data m-0">
                            <img :src="task.groups[0].image" />
                            <span class="user_name ml-2">{{
                              task.groups[0].name
                            }}</span>
                            <b-icon icon="menu-down mr-auto"></b-icon>
                          </div>
                        </a>
                      </template>
                      <b-dropdown-item
                        paddingless
                        aria-role="listitem"
                        v-for="item in task.users.slice(1)"
                        :key="item.id"
                      >
                        <div class="user_data mb-2">
                          <img :src="item.avatar" />
                          <span class="user_name">{{ item.name }}</span>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item
                        paddingless
                        aria-role="listitem"
                        v-for="item in task.groups"
                        :key="item.id"
                      >
                        <div class="user_data mb-2">
                          <img :src="item.image" />
                          <span class="user_name">{{ item.name }}</span>
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                    <h6 class="col-md-9">
                      {{ task.required }}
                    </h6>
                    <div class="list_actions">
                      <button type="button" class="btn text-center">
                        <img
                          style="width: 17px"
                          src="/media/svg/edit_metings.svg"
                        />
                      </button>
                    </div>
                  </div>
                  <div class="mt-5 position_relative">
                    <input
                      ref="editedTopic"
                      v-model="EditedTask.required"
                      class="form-control"
                      type="search"
                      name="meeting-link"
                      id="editedTopic"
                    />
                    <div class="text-right my-5">
                      <button
                        type="button"
                        @click="EditaskInPlace"
                        class="btn_Green btn_main"
                      >
                        حفظ
                      </button>
                    </div>
                  </div>
                </div>
                <div v-else class="d-block p-4">
                  <div class="d-flex position_relative">
                    <b-dropdown
                      position="is-bottom-left"
                      class="dropdownList"
                      aria-role="list"
                    >
                      <template #trigger>
                        <a
                          class="navbar-item p-0"
                          role="button"
                          v-if="task.users && task.users.length"
                        >
                          <div
                            v-if="task.users.length"
                            class="user_data bg_gray m-0"
                          >
                            <img :src="task.users[0].avatar" />
                            <span class="user_name ml-2">{{
                              task.users[0].name
                            }}</span>
                            <b-icon icon="menu-down mr-auto"></b-icon>
                          </div>
                          <div v-else class="user_data bg_gray m-0">
                            <img :src="task.groups[0].image" />
                            <span class="user_name ml-2">{{
                              task.groups[0].name
                            }}</span>
                            <b-icon icon="menu-down mr-auto"></b-icon>
                          </div>
                        </a>
                      </template>
                      <b-dropdown-item
                        paddingless
                        aria-role="listitem"
                        v-for="item in task.users.slice(1)"
                        :key="item.id"
                      >
                        <div class="user_data mb-2">
                          <img :src="item.avatar" />
                          <span class="user_name">{{ item.name }}</span>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item
                        paddingless
                        aria-role="listitem"
                        v-for="item in task.groups"
                        :key="item.id"
                      >
                        <div class="user_data mb-2">
                          <img :src="item.image" />
                          <span class="user_name">{{ item.name }}</span>
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                    <h6 class="col-md-9">
                      {{ task.required }}
                    </h6>
                    <div class="list_actions">
                      <button
                        type="button"
                        @click="EditTasks(task, index)"
                        class="btn text-center"
                      >
                        <img
                          style="width: 17px"
                          src="/media/svg/edit_metings.svg"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 col-12">
          <div
            class="title mt-6"
            style="justify-content: space-between; display: flex"
          >
            <span class="Centering">ملخص الإجتماع</span>
            <button
              type="button"
              @click="update_meeting_Note(meeting.note.id)"
              class="btn_second"
            >
              <img src="/media/svg/edit_w.svg" />
              تعديل
            </button>
          </div>
          <div class="text_editor">
            <wysiwyg placeholder="" v-model="meeting_Note" />
          </div>
        </div>

        <div class="invited_usesr w-100">
          <div class="row">
            <div
              class="col-md-6"
              v-for="element in meeting.users"
              :key="element.name"
            >
              <div class="d-flex">
                <vs-checkbox
                  @change="userToSendNote($event.target, element.id)"
                  style="height: 45px"
                ></vs-checkbox>
                <div class="user_data" style="width: 320px">
                  <img :src="element.avatar" />
                  <span class="user_name">{{ element.name }}</span>
                </div>
              </div>
            </div>
            <!-- <div
              class="col-md-6"
              v-for="element in meeting.groups"
              :key="element.name"
            >
              <div class="d-flex">
                <vs-checkbox color="#858585" style="height: 45px"></vs-checkbox>
                <div class="user_data" style="width: 320px">
                  <img :src="element.image" />
                  <span class="user_name">{{ element.name }}</span>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="col-md-8 col-12 my-5">
          <div class="title">
            <span>ارسال الاجتماع عن طريق</span>
          </div>

          <div class="display_responsive justify-content-between">
            <div class="w_30">
              <v-checkbox
                v-model="meeting.email_option"
                id="email"
                label="البريد الإلكتروني"
                color="red"
              ></v-checkbox>
            </div>

            <div class="w_30">
              <v-checkbox
                id="SMS"
                label="SMS"
                v-model="meeting.sms_option"
                color="red"
              ></v-checkbox>
            </div>
            <div class="w_30">
              <v-checkbox
                id="Whatsapp"
                label="Whatsapp"
                v-model="meeting.whatsapp_option"
                color="red"
              ></v-checkbox>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-12 form_footer">
          <div class="d-flex justify-content-end">
            <button class="btn_Green btn_main" @click="EditMeetingGeneral()">
              <span>حفظ البيانات</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import meetings from "@/http/requests/meetings";
import users from "@/http/requests/users";
import moment from "moment";
import ConvertDate from "../../../Helper/convert_date";
import vSelect from "vue-select";
import Multiselect from "vue-multiselect";
export default {
  name: "ShowMeeting",
  data() {
    return {
      TaskList: [],
      taskText: "",
      meetingsList: [],
      meeting: {},

      form_section1: {},
      start_time_h: null,
      end_time_h: null,
      meetingDate: null,

      myHTML: undefined,
      switch2: undefined,
      metting_time: "",
      Search_metting_value: undefined,
      value: undefined,

      allMembersSelected: [],
      allGroupsSelected: [],
      MeetingTasks: [],
      allMembers: [],
      taskUsers: [],
      taskGroups: [],
      Edit_Tasks: false,
      edited_task: "",
      EditedTask: {},
      NoteUsers: [],
      meeting_Note: "",
    };
  },
  components: {
    draggable,
    "v-select": vSelect,Multiselect
  },
  methods: {
    getMeetings(keyword) {
      this.meetingsList = [];
      meetings.getMeetings(1, keyword).then((res) => {
        this.meetingsList = res.data.data;
      });
    },
    getAllUser(keyword) {
      this.allMembers = [];
      users.getusers(1, keyword).then((res) => {
        this.allMembers = res.data.data;
      });
    },
    getMeeting() {
      this.meeting = {};
      meetings.getSingleMeeting(this.$route.params.id).then((res) => {
        this.meeting = res.data.data;
        this.allMembersSelected = res.data.data.users;
        this.allGroupsSelected = res.data.data.groups;
        this.MeetingTasks = res.data.data.tasks;
        if (res.data.data.note) {
          this.meeting_Note = res.data.data.note.note;
        }

        this.start_time_h = ConvertDate(
          moment(String(res.data.data.start_time)).format("h:mm:ss")
        );
        this.end_time_h = ConvertDate(
          moment(String(res.data.data.end_time)).format("h:mm:ss")
        );
        this.meetingDate = ConvertDate(
          moment(String(res.data.data.end_time)).format("YYYY-MM-DD")
        );
      });
    },
    EditMeetingGeneral() {
      this.$vs.loading();
      const form_section1 = {
        title: this.meeting.title,
        start_time: this.meetingDate + " " + this.start_time_h,
        end_time: this.meetingDate + " " + this.end_time_h,
        nature_of_meeting: this.meeting.nature_of_meeting,
        priority_level: this.meeting.priority_level,
        coordinator_id: this.meeting.coordinator.id,
        parent_id: this.meeting.parent_meeting.id,
        task_link: this.meeting.task_link,
        sms_option: this.meeting.sms_option,
        whatsapp_option: this.meeting.whatsapp_option,
        email_option: this.meeting.email_option,
      };

      meetings
        .EditMeeting(this.meeting.id, form_section1)
        .then(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            text: "تم تعديل الإجتماع بنجاح",
            color: "success",
          });
        })
     .catch((err) => {
            this.$vs.loading.close();
            if (err.response.data.error) {
              this.$vs.notify({
                text: err.response.data.error,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
                color: "danger",
              });
            }
          });
      },
    removeMember(index) {
      this.allMembersSelected.splice(index, 1);
    },
    removeGroup(index) {
      this.allGroupsSelected.splice(index, 1);
    },
    EditMeetingMembers() {
      this.$vs.loading();
      const form_section2 = {
        id: this.meeting.id,
        users: this.allMembersSelected.map((item) => item.id),
        groups: this.allGroupsSelected.map((item) => item.id),
      };

      meetings
        .EditMeetingMembers(form_section2)
        .then(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            text: "تم تعديل  اعضاء الإجتماع بنجاح",
            color: "success",
          });
        })
       .catch((err) => {
            this.$vs.loading.close();
            if (err.response.data.error) {
              this.$vs.notify({
                text: err.response.data.error,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
                color: "danger",
              });
            }
          });
      },
    EditTasks(task, index) {
      this.Edit_Tasks = true;
      this.edited_task = index;
      this.EditedTask = task;
    },
    EditaskInPlace(index) {
      this.Edit_Tasks = false;
      this.MeetingTasks[index] = this.EditedTask;
      // this.$vs.notify({
      //       text: "تم تعديل  المهمه بنجاح",
      //       color: "success",
      //     });
    },
    update_meeting_tasks() {
      this.$vs.loading();
      const form_section3 = {
        id: this.meeting.id,
        tasks: this.MeetingTasks,
      };
      meetings
        .update_meeting_tasks(form_section3)
        .then(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            text: "تم تعديل  مهام الإجتماع بنجاح",
            color: "success",
          });
        })
       .catch((err) => {
            this.$vs.loading.close();
            if (err.response.data.error) {
              this.$vs.notify({
                text: err.response.data.error,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
                color: "danger",
              });
            }
          });
      },
    userToSendNote(event, user_id) {
      if (event.checked) {
        this.NoteUsers.push(user_id);
      } else {
        for (let i = 0; i <= this.NoteUsers.length; i++) {
          if (this.NoteUsers[i] == user_id) {
            this.NoteUsers.splice(i, 1);
          }
        }
      }
    },
    update_meeting_Note(note_id) {
      this.$vs.loading();
      const form_note = {
        note: this.meeting_Note,
        users: this.NoteUsers,
        meeting_id:this.meeting.id
      };
      meetings
        .update_note_meeting(note_id, form_note)
        .then(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            text: "تم تعديل  ملخص الإجتماع بنجاح",
            color: "success",
          });
        })
      .catch((err) => {
            this.$vs.loading.close();
            if (err.response.data.error) {
              this.$vs.notify({
                text: err.response.data.error,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
                color: "danger",
              });
            }
          });
      },
    btnAddTask() {
      this.taskUsers = [];
      this.taskGroups = [];
      for (let i = 0; i < this.TaskList.length; i++) {
        if (this.TaskList[i].email) {
          this.taskUsers.push(this.TaskList[i]);
        } else {
          this.taskGroups.push(this.TaskList[i]);
        }
      }
      if (this.taskUsers.length || this.taskGroups.length) {
        this.MeetingTasks.push({
          required: this.taskText,
          user: this.taskUsers.map((item) => item.id),
          group: this.taskGroups.map((item) => item.id),
        });
        this.TaskList = [];
        this.taskText = "";
      } else {
        this.$vs.notify({
          text: "برجاء اختيار عضو او مجموعة",
          color: "danger",
        });
      }
    },
  },
  created() {
    this.getMeeting();
    this.getMeetings();
    this.getAllUser()
  },
};
</script>

